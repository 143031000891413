if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/offline-service-worker.js').then(registration => {
            console.log('ServiceWorker registration successful with scope: ', registration.scope);

            // Request persistent storage
            if (navigator.storage && navigator.storage.persist) {
                navigator.storage.persist().then(persistent => {
                    if (persistent) {
                        console.log("Storage will not be cleared except by explicit user action");
                    } else {
                        console.log("Storage may be cleared by the UA under storage pressure.");
                    }
                });
            }

        }, err => {
            console.log('ServiceWorker registration failed: ', err);
        });
    });
}