import Vuex from "vuex";
const store = new Vuex.Store({
    // Your store properties (state, mutations, actions, etc.)
    state: {
        user_id: 0,
        token: null,
        online: false,
        lat: null,
        lng: null
    },
    mutations: {
        UPDATE_USER_ID(state, value) {
            state.user_id = value;
        },
        UPDATE_TOKEN(state, value) {
            state.token = value;
        },
        UPDATE_ONLINE(state, value) {
            state.online = value;
        },
        UPDATE_LAT(state, value) {
            state.lat = value;
        },
        UPDATE_LNG(state, value) {
            state.lng = value;
        }
    },
    getters: {
        user_id: state => state.user_id,
        token: state => state.token,
        online: state => state.online,
        latitude: state => state.lat,
        longitude: state => state.lng
    },
    actions: {
        initializeStore({ state }) {
            // Check if the ID exists
            if (localStorage.getItem('store')) {
                // Replace the state object with the stored item
                this.replaceState(
                    Object.assign(state, JSON.parse(localStorage.getItem('store')))
                );
            }
        },
        resetStore({commit}){
            commit('UPDATE_USER_ID', null);
            commit('UPDATE_TOKEN', null);
        },
        updateUserID({ commit }, value) {
            commit('UPDATE_USER_ID', value);
        }
    }
});

store.subscribe((mutation, state) => {
    // Store the state object as a JSON string
    localStorage.setItem('store', JSON.stringify(state));
});

export default store;