import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import ChooseSurvey from "@/views/ChooseSurvey.vue";
import MySurveys from "@/views/MySurveys.vue";
import EditSurvey from "@/views/EditSurvey.vue";
const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/edit-survey/:survey_id',
        name: 'edit-survey',
        component: EditSurvey
    },
    {
        path: '/choose-survey',
        name: 'choose-survey',
        component: ChooseSurvey
    },
    {
        path: '/my-surveys',
        name: 'my-surveys',
        component: MySurveys
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
