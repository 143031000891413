import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import Vue3Signature from "vue3-signature"

import './app.css';
import 'flowbite';
import store from '@/stores/userStore';
import router from '@/router.js';

store.dispatch('initializeStore');

import Notifications from '@kyvg/vue3-notification'


import {initFlowbite} from "flowbite";

initFlowbite();
createApp(App)
    .use(router)
    .use(Notifications)
    .use(Vue3Signature)
    .use(store)
    .mount('#app')
