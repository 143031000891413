<template>
    <div style="min-height: 100vh;">
      <div class="q-pa-md" style="max-width: 400px">
        <form
            method="post"
            @submit.prevent="submit"
        >
          <div class="flex flex-col items-center justify-center gap-4">
            <div class="flex flex-col items-center gap-2">
              <label>Email</label>
              <input autocomplete="email" class="rounded-lg" type="email" v-model="email" name="email">
            </div>
            <div class="flex flex-col items-center gap-2">
              <label>Password</label>
              <input autocomplete="current-password" class="rounded-lg" type="password" name="password" v-model="password">
            </div>
          </div>

          <div class="flex mt-4 mb-4 flex-col items-center justify-center gap-4">
            <div>
              <button class="bg-blue-500 mr-1 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-lg" type="submit" color="primary">Submit</button>
              <button class="bg-red-500 ml-1 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-lg" @click="reset()">Reset</button>
            </div>
          </div>

        </form>
      </div>
    </div>
</template>

<style>
</style>

<script>
import axios from 'axios'
export default {
  name: 'PageLogin',
  data(){
    return {
      email: null,
      password: null
    }
  },
  methods: {
      submit () {
        this.getUserLocation();
        let submitData = new FormData();
        submitData.append('email', this.email);
        submitData.append('password', this.password);
        axios.post('https://lots.blackwidow.org.za/api/login', submitData)
            .then((res) => {
              this.$store.commit('UPDATE_USER_ID',res.data.user_id);
              this.$store.commit('UPDATE_TOKEN',res.data.token);
              localStorage.setItem('user_id', res.data.user_id)
              localStorage.setItem('token', res.data.token)
              this.$router.push({ name: "home"})
            })
            .catch((e) => {
              alert(e)
            }).finally(() => {
          //Perform action in always
        });
      },
      getUserLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            console.log(position.coords.latitude, position.coords.longitude);
            this.$store.commit('UPDATE_LAT',position.coords.latitude);
            this.$store.commit('UPDATE_LNG',position.coords.longitude);
          });
        } else {
          console.log("Geolocation is not supported by this browser.");
        }
      },
      reset () {
        this.email = null
        this.password = null
      }
    }
}
</script>
