<template>
  <div>
    <div>
      <div class="bg-gray-100 dark:bg-gray-800">
        <div class="max-w-screen-xl mx-auto p-4">
          <div class="flex items">
            <div class="flex-1">
              <h1 class="text-2xl font-semibold dark:text-gray-100">
                <span v-if="this.online">You are online!</span>
                <span v-if="!this.online" class="text-red-900">You are Offline!</span>
              </h1>
            </div>
          </div>
          <div class="flex items-center space-x-2 space-y-2">
            <button @click="getSurveys" class="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-lg">Sync Survey Questions</button>
            <button @click="chooseSurvey()" class="bg-green-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-lg">Perform survey</button>
            <button @click="mySurveys()" class="bg-gray-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-lg">My Surveys</button>
          </div>
          <div>
            <div class="flex items flex-row">
              <ul>
                <h5>Currently have {{surveys.length}} surveys on this device </h5>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
        db : null,
        array: null,
        surveys : [],
    }
  },
  computed: {
    online: function(){
      return this.$store.getters.online;
    },
    user_id : function(){
      return this.$store.getters.user_id;
    },
    token : function(){
      return this.$store.getters.token;
    }
  },
  mounted() {
    const request = window.indexedDB.open("SurveyDatabase", 2);

    request.onerror = (event) => {
      console.error("Database error: " + event.target.errorCode);
    };

    request.onsuccess = (event) => {
      this.db = event.target.result; // `this` refers to the Vue instance
      console.log("Database opened successfully");
      if(this.online){
        this.getSurveys();
      }
      this.readSurveys();
    };

    request.onupgradeneeded = (event) => {
      this.db = event.target.result;
      if (!this.db.objectStoreNames.contains("SurveyDataStore")) {
        this.db.createObjectStore("SurveyDataStore", { keyPath: "id" });
        console.log("Survey Object store created");
      }
      if (!this.db.objectStoreNames.contains("AnswersDataStore")) {
        this.db.createObjectStore("AnswersDataStore", { keyPath: "id" });
        console.log("Answers Object store created");
      }
    };
  },
  methods: {
    mySurveys() {
      this.$router.push({ name: "my-surveys" });
    },
    chooseSurvey() {
      this.$router.push({ name: "choose-survey" });
    },
    getSurveys(){

      if(this.online){
        if(this.token!==null) {
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
          axios.get('https://lots.blackwidow.org.za/api/surveys')
              .then((res) => {
                if (!this.db) {
                  console.error("Database is not initialized");
                  return;
                }
                const dbTransaction = this.db.transaction(["SurveyDataStore"], "readwrite");
                const store = dbTransaction.objectStore("SurveyDataStore");

                store.clear().onsuccess = function() {
                  // After clearing, insert the new records
                  res.data.forEach(function (survey) {
                    const item = {
                      id: survey.id,
                      content: survey,
                    };
                    console.log(item);
                    store.put(item);
                  });
                };

                this.$notify({
                  title: "Sync Complete",
                  text: "Successfully downloaded questions!",
                  type : 'success',
                  duration : 500
                });


                dbTransaction.oncomplete = () => {
                  console.log("Data added to the store successfully");
                  this.readSurveys();
                };

                dbTransaction.onerror = (event) => {
                  console.error("Transaction error: ", event.target.error);
                };
              });
        }
      }else{
        this.$notify({
          title: "You are offline",
          text: "You are offline!",
          type : 'error',
          duration : 500
        });
      }
    },
    readSurveys(){
      if (!this.db) {
        console.error("Database is not initialized");
        return;
      }
      const transaction = this.db.transaction(["SurveyDataStore"]);
      const objectStore = transaction.objectStore("SurveyDataStore");
      const request = objectStore.getAll();

      request.onerror = (event) => {
        console.error("Could not retrieve data: ", event.target.error);
      };

      request.onsuccess = () => {
        if (request.result) {
          this.surveys = request.result;
          console.log("Data retrieved: ", request.result);
        } else {
          console.log("No data found with id 1");
        }
      };
    }
  }

}
</script>

